import React, { useEffect } from 'react'

import styled from '@emotion/styled'
import Head from 'next/head'
import { useDispatch, useSelector } from 'react-redux'

import ThreeColumnLayout from '@components/home/three-column-layout'
import { track } from 'analytics'
import LocationForm from 'components/blog/location-form'
import { TEST_IDS } from 'components/blog/test/constants.js'
import Footer from 'components/footer'
import FourTileLayout from 'components/home/four-tile-layout'
import LogoGallery from 'components/home/logo-gallery'
import ProductGallery from 'components/home/product-gallery'
import Modal from 'components/modal/container'
import Navbar from 'components/navbar'
import ROUTES from 'helpers/routes'
import { toggleAddress, setShouldRedirect, setRedirectLocation } from 'redux/addressModal/actions'
import { getResidentialOnlyError } from 'redux/addressModal/selectors'
import { isLoggedIn } from 'redux/auth/selectors'
import { handleHomeLocation } from 'redux/blog/operations'
import { getAddressLoading } from 'redux/loading/selectors'
import { getActiveLocation } from 'redux/location/selectors'

import { arrayOf, object, shape } from 'prop-types'

function Homepage({ pageACF }) {
  const dispatch = useDispatch()
  const addressLoading = useSelector(getAddressLoading)
  const activeLocation = useSelector(getActiveLocation)
  const inSampleLocation = useSelector((state) => state.location.inSampleLocation)
  const residentialOnlyError = useSelector(getResidentialOnlyError)
  const userLoggedIn = useSelector(isLoggedIn)

  useEffect(() => {
    dispatch(setShouldRedirect(true))
    dispatch(setRedirectLocation(ROUTES.MENU))

    return () => {
      dispatch(setShouldRedirect(false))
      dispatch(setRedirectLocation(''))
    }
  }, [dispatch])

  const handleLocationChange = (address) => {
    dispatch(handleHomeLocation(address))
  }

  const handleAddressSubmission = (redirectLocation) => {
    track('Places.View', { destination: redirectLocation })
    dispatch(toggleAddress(redirectLocation))
  }

  const componentToRender = (layoutName) => {
    const components = {
      three_column_layout: ThreeColumnLayout,
      four_tile_layout: FourTileLayout,
      product_gallery: ProductGallery,
      logo_gallery: LogoGallery
    }

    return components[layoutName]
  }

  return (
    <PageContainer data-e2eid={TEST_IDS.PAGE}>
      <Head>
        <link rel="canonical" href={`https://www.eaze.com/`} key="canonical" />
      </Head>
      <Navbar hideLogoAtTop place={activeLocation} showLoginShopButtons showLogoInMiddle showScrollEffect />

      <LocationForm
        addressLoading={addressLoading}
        place={activeLocation}
        onLocationChange={handleLocationChange}
        isLoggedIn={userLoggedIn}
        inSampleLocation={inSampleLocation}
        residentialOnlyError={residentialOnlyError}
        formLocation="header"
      />

      {pageACF.page_sections.map((layout) => {
        const layoutName = layout.acf_fc_layout
        const CustomComponent = componentToRender(layoutName)
        return <CustomComponent key={layoutName} toggleAddress={handleAddressSubmission} {...layout} />
      })}

      <LocationForm
        addressLoading={addressLoading}
        place={activeLocation}
        onLocationChange={handleLocationChange}
        isLoggedIn={userLoggedIn}
        inSampleLocation={inSampleLocation}
        residentialOnlyError={residentialOnlyError}
        formLocation="footer"
      />

      <Footer />

      <Modal />
    </PageContainer>
  )
}

Homepage.propTypes = {
  pageACF: shape({
    page_sections: arrayOf(object)
  }).isRequired
}

const PageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`

export default Homepage
