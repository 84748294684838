import styled from '@emotion/styled'

import onClickEvents from '@helpers/home/on-click-callbacks'
import Colors from 'microcomponents/colors'

import { TEST_IDS } from './test/constants'

import { bool, func, oneOfType, shape, string } from 'prop-types'

const LogoGallery = (props) => {
  if (props.desktop_image && props.mobile_image) {
    const bannerName = props.banner_name
    const redirectLocation = props.call_to_action ? props.call_to_action.url : '/menu'

    return (
      <GalleryContainer data-e2eid={TEST_IDS.LOGO_GALLERY}>
        <Title>{props.section_title}</Title>
        <ImageContainer
          className="desktop"
          onClick={() => onClickEvents(bannerName, redirectLocation, props.toggleAddress)}>
          <DesktopImage
            src={props.desktop_image.sizes.medium}
            alt={props.desktop_image.alt || 'Featured brands logos'}
          />
        </ImageContainer>
        <ImageContainer
          className="mobile"
          onClick={() => onClickEvents(bannerName, redirectLocation, props.toggleAddress)}>
          <MobileImage
            src={props.mobile_image.sizes.mobileImage2x}
            alt={props.mobile_image.alt || 'Featured brands logos'}
          />
        </ImageContainer>
        <ShopNow onClick={() => onClickEvents(bannerName, redirectLocation, props.toggleAddress, 'shop_now')}>
          {props.call_to_action.title || 'SHOP NOW'}
        </ShopNow>
      </GalleryContainer>
    )
  } else {
    return null
  }
}

LogoGallery.propTypes = {
  banner_name: string,
  call_to_action: shape({
    title: string,
    url: string
  }),
  section_title: oneOfType([bool, string]),
  desktop_image: oneOfType([
    bool,
    shape({
      url: string
    })
  ]),
  mobile_image: oneOfType([
    bool,
    shape({
      url: string
    })
  ]),
  toggleAddress: func
}

const colorMap = {
  yellow: Colors.homepageRedesign[1],
  black: Colors.homepageRedesign[0]
}

const DesktopImage = styled.img`
  max-width: 815px;
  height: auto;
  margin-bottom: 10px;
`

const MobileImage = styled.img`
  width: 294px;
  height: auto;
  margin-bottom: 18px;
`

const Title = styled.h3`
  font-family: 'Suisse Intl Mono Bold', monospace;
  font-size: 24px;
  display: inherit;
  flex-flow: inherit;
  justify-content: inherit;
  width: 100%;
`

const GalleryContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 40px auto;

  a {
    color: ${colorMap.black};
  }

  @media (min-width: 767px) {
    h3 {
      display: inherit;
      flex-flow: inherit;
      justify-content: inherit;
      margin: 50px;
    }

    .mobile {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .desktop {
      display: none;
    }
  }
`

const ImageContainer = styled.a`
  img {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
`

const ShopNow = styled.a`
  background-color: ${colorMap.yellow};
  font-family: Gilroy;
  font-style: italic;
  line-height: 1.24;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.27);
  padding: 10px 0;
  text-align: center;
  flex-basis: 100%;
  justify-content: center;
  font-size: 17px;
  min-width: 129px;

  @media (min-width: 767px) {
    margin: 40px 535px;
  }

  @media (max-width: 767px) {
    margin: 40px 352px 90px;
  }
`

export default LogoGallery
