import styled from '@emotion/styled'

import onClickEvents from '@helpers/home/on-click-callbacks'

import { TEST_IDS } from './test/constants'

import { arrayOf, bool, func, InferProps, number, oneOfType, shape, string } from 'prop-types'

type ProductGalleryProps = InferProps<typeof ProductGallery.propTypes>

function ProductGallery(props: ProductGalleryProps): JSX.Element {
  if (props.products) {
    return (
      <FeaturedContainer data-e2eid={TEST_IDS.PRODUCT_GALLERY}>
        <Title>{props.section_title}</Title>
        {props.products.map((product, i) => {
          const bannerName = props.banner_name
          const redirectLocation = props.call_to_action ? props.call_to_action.url : '/menu'
          const bannerItem = product.banner_item

          return (
            <Product
              key={i}
              onClick={() => onClickEvents(bannerName, redirectLocation, props.toggleAddress, bannerItem)}>
              <ProductImage
                src={product.product_image.url}
                alt={product.product_image.alt || product.product_name}
                srcSet={`${product.product_image.sizes.thumbnail} ${product.product_image.sizes['thumbnail-width']}w, ${product.product_image.sizes.mobileImage} ${product.product_image.sizes['mobileImage-width']}w`}
                sizes={`(max-width: 767px) ${product.product_image.sizes['mobileImage-width']}w, ${product.product_image.sizes['thumbnail-width']}w`}
              />
            </Product>
          )
        })}
      </FeaturedContainer>
    )
  } else {
    return null
  }
}

ProductGallery.propTypes = {
  section_title: oneOfType([bool, string]),
  banner_name: string,
  call_to_action: shape({
    url: string
  }),
  toggleAddress: func,
  products: arrayOf(
    shape({
      product_image: shape({
        url: string,
        alt: string,
        sizes: shape({
          thumbnail: string,
          'thumbnail-width': number,
          mobileImage: string,
          'mobileImage-width': number
        })
      }),
      product_name: string,
      banner_item: string
    })
  )
}

const Title = styled.h3`
  display: inherit;
  flex-flow: inherit;
  justify-content: inherit;
  margin: 50px;
  margin-bottom: 40px;
  width: 100%;
  font-family: 'Suisse Intl Mono Bold', monospace;
  font-size: 24px;
`

const ProductImage = styled.img`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

const FeaturedContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  // Desktop view
  @media (min-width: 767px) {
    margin-top: 10px;

    h3 {
      margin: 50px;
      margin-bottom: 40px;
    }
  }

  // Mobile View
  @media (max-width: 767px) {
    margin-bottom: 40px;

    h3 {
      margin-bottom: 10px;
    }
  }
`

const Product = styled.a`
  // Desktop view
  @media (min-width: 767px) {
    img {
      max-height: 173px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin: 5px 10px;
      margin-bottom: 50px;
    }
  }

  // Mobile view
  @media (max-width: 767px) {
    margin: 29px;

    img {
      max-height: 173px;
    }
  }
`

export default ProductGallery
