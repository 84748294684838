import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import getWordpressData from '@helpers/word-press/get-wordpress'
import getSplitTreatments from 'bff/middleware/request-split-treatments'
import HomePage from 'components/home'
import ROUTES from 'helpers/routes'
import withPage from 'hocs/page'

import { bool } from 'prop-types'

function IndexPage(props) {
  const { showRedesign } = props
  return showRedesign ? <HomePage {...props} /> : <HomePage {...props} />
}

IndexPage.propTypes = {
  showRedesign: bool
}

export async function getServerSideProps({ req }) {
  const { cookies } = req
  const hasLocationSet = cookies.eaze_coordinates && cookies.eaze_placeId
  // cookies.eaze_loggedin is a boolean in a string ('true' || 'false') so check for existence and parse
  const isLoggedIn = cookies.eaze_loggedin && JSON.parse(cookies.eaze_loggedin)

  // Grab Redesign Split Feature Flag
  const splitAnonIdExperiments = [SPLIT_EXPERIMENTS.REDESIGN]
  const { splitTreatments } = await getSplitTreatments(splitAnonIdExperiments, req, false)
  const showRedesign = splitTreatments?.[SPLIT_EXPERIMENTS.REDESIGN]?.treatment === SPLIT_TREATMENTS.REDESIGN.ON

  if (hasLocationSet || isLoggedIn) {
    return {
      redirect: {
        destination: ROUTES.MENU,
        permanent: false
      }
    }
  }

  const data = await getWordpressData('landing')

  return {
    props: {
      ...data,
      showRedesign
    }
  }
}

export default withPage(IndexPage)
